import React, { createContext, useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
    const [pmss, setPmss] = useState(null);
    const [year, setYear] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fonction pour récupérer le PMSS depuis l'API
    const fetchConfig = async () => {
        try {
            setLoading(true);
            const response = await fetch('https://mzb659av0h.execute-api.us-east-1.amazonaws.com/integration/config');
            if (!response.ok) {
                throw new Error(`Erreur HTTP : ${response.status}`);
            }
            const data = await response.json();

            // Parser la valeur encodée dans "body"
            const parsedBody = JSON.parse(data.body);

            const pmssValue = parsedBody.pmss;
            const yearValue = parsedBody.year;

            setPmss(pmssValue);
            setYear(yearValue);
        } catch (error) {
            console.error('Erreur lors de la récupération des données :', error.message);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const updateConfig = async (newPmss, newYear) => {
        try {
            // Vérification si les deux valeurs sont inchangées
            if (newPmss === pmss && newYear === year) {
                toast.error("Aucune valeur n'a été modifiée.");
                console.error("Erreur : Aucune valeur n'a été modifiée.");
                return;
            }

            setLoading(true);

            // Vérifiez ce qui est envoyé à l'API
            console.log("Valeurs envoyées à l'API PUT :", { pmss: newPmss, year: newYear });

            const response = await fetch('https://mzb659av0h.execute-api.us-east-1.amazonaws.com/integration/config', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ pmss: newPmss, year: newYear })
            });

            if (!response.ok) {
                const errorData = await response.json(); // Récupérer l'erreur précise
                console.error('Erreur API PUT :', errorData);
                throw new Error(`Erreur HTTP : ${response.status}`);
            }

            const data = await response.json();
            const parsedBody = JSON.parse(data.body); // Si la réponse est encodée

            // Mettre à jour les deux états
            setPmss(parsedBody.pmss);
            setYear(parsedBody.year);

            toast.success(`PMSS et année mis à jour avec succès : ${newPmss} € pour ${newYear}`);
        } catch (error) {
            console.error('Context : Erreur lors de la mise à jour du PMSS :', error.message);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    // Récupérer le PMSS lors du montage du composant
    useEffect(() => {
        fetchConfig();
    }, []);

    return (
        <ConfigContext.Provider value={{ pmss, year, setPmss, setYear, updateConfig, loading }}>
            {children}
        </ConfigContext.Provider>
    );
};

export const useConfig = () => useContext(ConfigContext);
