import { Container, Paper } from '@mui/material';
import React from 'react';
import Navbar from '../components/componentsAdmin/Navbar';
import { useNavigate } from 'react-router-dom';
import '../styles/stylesAdmin/AccountPage.css';
import TableauUser from '../components/componentsAdmin/TableUsers';
import { useUserContext } from '../Context/UsersContext';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

function AccountPage() {
    const navigate = useNavigate();

    const { user, setUser } = useUserContext();

    const handleForm = () => {
        navigate('/formUser');
    };

    return (
        <div className="comptpage">
            <Navbar />
            <div className="comptpage-container">
                <div className="comptpage-table">
                    <div className="comptpage-top">
                        <button className="comptpage-top-btn " onClick={handleForm}>
                            <PersonAddAltIcon />
                        </button>
                    </div>
                    <Container
                        style={{
                            paddingLeft: '12px',
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
                        }}
                    >
                        <TableauUser />
                    </Container>
                </div>
            </div>
        </div>
    );
}

export default AccountPage;
