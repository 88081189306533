import React from 'react'
import "../../stylesComponents/sCAdmin/Navbar.css"
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import ListMenu from './ListMenu';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';


function Navbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate()
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    navigate("/profile")
    setAnchorEl(null)
  }

  const handleLogout = () => {
    navigate("/")
    setAnchorEl(null);
  }

  const extractInitialsFromEmail = (email) => {
    if (!email) return " "; 

    const parts = email.split("@")[0].split(".");
    const firstInitial = parts.length > 0 ? parts[0][0] : "";
    const secondInitial = parts.length > 1 ? parts[1][0] : "";

    return firstInitial.toUpperCase() + secondInitial.toUpperCase();
  };

  const email = Cookies.get("email"); 
  const initials = extractInitialsFromEmail(email);

  return (
    <div className='navbar'>
       <div className='navbar-container'>
        <div className='nav-champ1'>
            <ListMenu/>
        </div>
        <div className='nav-champ2'>
          <ul className='nav-ul'>
            <li className='nav-li'>
              <form className='nav-form-search'>
              {/* <input className='nav-input-search'/>
              <button className='nav-btn-search'><SearchIcon/></button> */}
              </form>
              </li>
              <li className='nav-li'>
              <Stack direction="row" spacing={2}>
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
              <Avatar>{initials}</Avatar>
              </Button>
              <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem className="menu-item" onClick={handleClose}>
                      <button className="menu-item-button">
                        <Link className="menu-link" to="/status"> Profil Commercial</Link>
                      </button> 
                    </MenuItem>
                    {/* <hr/>
                    <MenuItem className="menu-item" onClick={handleProfile }>
                      <SettingsIcon sx={{marginRight: 1}}/>Parametre
                    </MenuItem> */}
                    <hr/>
                    <MenuItem className="menu-item" onClick={handleLogout}>
                      <LogoutIcon sx={{marginRight: 1}}/>Deconnexion
                    </MenuItem>
                  </Menu>
              </Stack>
              </li>
          </ul>
        </div>
       </div>
    </div>
  )
}

export default Navbar