import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import LogoPr from '../../../assets/LogoPr.jpg';
import LogoPRO from '../../../assets/LogoPRO.png';
import './../../../stylesComponents/sCClient/stylesTemplates/Template1.css';

function Template1() {
    const user = JSON.parse(localStorage.getItem('user'));
    const [firstName, setFirstname] = useState('');
    const [lastName, setLastname] = useState('');

    useEffect(() => {
        const email = Cookies.get('email');
        //console.log("Email récupéré du cookie:", email);

        if (email) {
            const [fullName] = email.split('@');
            const nameParts = fullName ? fullName.split('.') : [];

            let firstName = '';
            let lastName = '';

            if (nameParts.length === 2) {
                firstName = nameParts[0];
                lastName = nameParts[1];
            } else if (nameParts.length === 1) {
                firstName = nameParts[0];
                lastName = '';
            } else {
                console.warn("Le format de l'email ne correspond pas au format attendu prénom.nom@domaine.com");
            }

            const capitalize = (str) => str && str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

            console.log('Prénom extrait:', firstName);
            console.log('Nom extrait:', lastName);

            const prenom = capitalize(firstName);
            const nom = capitalize(lastName);

            setFirstname(prenom);
            setLastname(nom);
        } else {
            console.warn('Email non trouvé dans les cookies');
        }
    }, []);

    return (
        <div>
            <div id="invoiceholder-tp1">
                <div id="template-1">
                    <div className="logo">
                        <img src={LogoPr} alt="logo" className="logo-top" />
                    </div>
                    <div id="template1-top">
                        <div className="tp1-text-left">
                            <p className="entreprise-info">
                                <h3 id="tp1-title">Contact</h3>
                                <span id="address" style={{ fontSize: 11 }}>
                                    prodigeoassurances.com
                                </span>
                                <span id="city" style={{ fontSize: 14 }}>
                                    PRODIGÉO ASSURANCES
                                </span>
                                <span id="country" style={{ fontSize: 12 }}>
                                    93901 BOBIGNY CEDEX 9
                                </span>
                                <span id="tax_num" style={{ fontSize: 12 }}>
                                    Accueil au 04 93 22 73 76
                                </span>
                            </p>
                        </div>
                        <div className="tp1-text-right">
                            <div className="info-client">
                                <p style={{ fontSize: 14, marginBottom: 13 }}>
                                    {user.nom} {user.prenom}
                                </p>
                                <span style={{ fontSize: 11, marginBottom: 13 }}>
                                    {user.adress}
                                    <br></br>
                                    {user.cp} {user.ville}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="title-city">
                        <p>
                            <span>
                                {user.ville}, le {user.datecreation}
                            </span>
                        </p>
                    </div>
                    <div id="template1-subtop">
                        <div className="title">
                            <span style={{ fontSize: 14 }}>Vos réferences : {user.muna}</span>
                            <br />
                            <span className="titre">Votre étude : Contrat collectif facultatif</span>
                        </div>
                    </div>
                    <div className="tp1-body">
                        <span>{user.civilite},</span>
                        <div className="tp1-champ1">
                            <span>Suite a notre entretien, vous trouverez ci-après :</span>
                            <ul>
                                <li className="tp1-li"> la (ou les) proposition(s) tarifaire(s)</li>
                                <li className="tp1-li">le bulletin individuel d’adhésion à compléter et signer</li>
                                <li className="tp1-li">le tableau récapitulatif des garanties</li>
                            </ul>
                        </div>
                        <div className="tp1-champ2">
                            <h4 className="title1">Pour mettre en place vos garanties, c'est simple :</h4>
                            <p style={{ fontSize: 12, marginBottom: 7 }}>
                                Il vous suffit de nous retourner votre bulletin individuel d'adhésion signé, accompagné
                                des pièces justificatives demandées.
                            </p>
                            <ul>
                                <li className="tp1-li">
                                    Depuis votre Espace abonné «Mon compte» pour un suivi plus rapide sur{' '}
                                    <i className="lien" style={{ cursor: 'pointer' }}>
                                        https://www.prodigeoassurances.com
                                    </i>
                                </li>
                                <li className="tp1-li">ou par courrier (voir adresse postale en tête de page).</li>
                            </ul>
                            <p className="textq" style={{ fontSize: 11 }}>
                                Vous avez besoin d'un conseil, d'une information ?
                            </p>

                            <h4 className="title2">
                                Notre équipe de conseillers est à votre écoute au 01 57 63 66 13 du lundi au vendredi de
                                8h30 à 19h et le samedi de 8h30 à 13h.
                            </h4>
                        </div>
                        <div className="tp1-champ3">
                            <p>Je vous prie d'agréer, {user.civilite}, l'expression de mes sentiments distingués.</p>
                            <br />
                            <p>Votre conseiller PRODIGÉO Assurances</p>
                            <p>
                                <i>{firstName}</i> <i>{lastName}</i>
                            </p>
                        </div>
                    </div>
                    <div className="tp1-footer">
                        <img className="tp1-img-footer" src={LogoPRO} alt="alt" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Template1;
