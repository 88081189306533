import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/stylesAuthentification/Auth.css';
import Logo from './../assets/logo Prodbleu.png';
import { Auth } from 'aws-amplify';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const initialState = {
    email: '',
    code: ''
};

function ConfirmSignUp() {
    const navigate = useNavigate();
    const [formData, setFormdata] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState({ type: '', text: '' });

    const handleChange = (e) => {
        setFormdata({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleCo = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setMessage({ type: '', text: '' });

        const maxRetries = 3;
        let attempt = 0;

        async function confirm() {
            try {
                await Auth.confirmSignUp(formData.email, formData.code);
                setMessage({
                    type: 'success',
                    text: "Votre compte est validé. Vous pouvez cliquer sur 'Mot de passe oublié' pour choisir un mot de passe."
                });
                setTimeout(() => {
                    navigate('/forgotPassword');
                }, 12000);
            } catch (e) {
                if (e.code === 'LimitExceededException' && attempt < maxRetries) {
                    attempt++;
                    setTimeout(confirm, Math.pow(2, attempt) * 1000); // Backoff exponentiel
                } else {
                    setMessage({
                        type: 'error',
                        text: e.message || "Une erreur s'est produite. Veuillez réessayer."
                    });
                }
            } finally {
                setIsLoading(false);
            }
        }

        confirm();
    };

    const handleBack = () => {
        navigate('/');
    };

    const handleNext = () => {
        navigate('/forgotPassword', { state: { email: formData.email } });
    };

    return (
        <div className="container-main">
            <div className="container-second">
                <div className="wrap">
                    <div className="auth-header">
                        <img className="logo-prodigeo" src={Logo} alt="LOGO" />
                        <h2 className="auth-title">Validation</h2>
                    </div>
                    <form onSubmit={handleCo}>
                        <label className="form-label">Adresse email</label>
                        <input
                            value={formData.email}
                            type="email"
                            className="auth-input"
                            placeholder="xxxx@xxx.com"
                            onChange={handleChange}
                            name="email"
                            required
                        />
                        <label className="auth-label">Code de vérification (envoyé sur votre boîte email)</label>
                        <input
                            value={formData.code}
                            type="text"
                            className="password-input auth-input"
                            placeholder="**********"
                            name="code"
                            required
                            onChange={handleChange}
                        />
                        <Stack spacing={2} sx={{ width: '100%' }}>
                            {message.text && <Alert severity={message.type}>{message.text}</Alert>}
                        </Stack>
                        <button type="submit" className="auth-btn" disabled={isLoading} onClick={handleNext}>
                            {isLoading ? 'Chargement...' : 'Confirmer votre mail avec le code'}
                        </button>
                    </form>
                    <button type="submit" className="login-back" disabled={isLoading} onClick={handleBack}>
                        {isLoading ? (
                            ''
                        ) : (
                            <span
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}
                            >
                                <ArrowBackIcon style={{ fontSize: '20px' }} />
                                Retour à la page de login
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ConfirmSignUp;
