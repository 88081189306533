import React from "react";
import Navbar from "../../components/componentsAdmin/Navbar";
import "../../styles/stylesAdmin/NewBranch.css";
import FormBranch from "../../components/componentsAdmin/FormBranch";


function NewBranch() {
  return (
    <div className="newbranche">
      <Navbar />
      <div className="newbranche-container">
        <h1>Formulaire de creation d’une branche</h1>
        <div>
         <FormBranch/>
        </div>
      </div>
    </div>
  );
}
export default NewBranch;
