import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import "../styles/stylesAuthentification/Choice.css";

function Choice() {
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState("USER"); // Valeur par défaut

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const session = await Auth.currentSession();
        const groups = session.getIdToken().payload["cognito:groups"] || [];
        if (groups.length > 0) {
          setUserRole(groups[0]); // Supposons que le premier groupe est le rôle principal
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du groupe de l'utilisateur: ", error);
      }
    };

    fetchUserRole();
  }, []);

  const handleComer = () => {
    navigate("/status");
  };

  const handleAdm = () => {
    navigate("/accountUser");
  };

  return (
    <div className="container">
      <div className="container-choix">
        <div className="wrap-choix">
          <div className="choix-header">
            <h2 className="choix-form-title"> Vous connecter :</h2>
          </div>
          <div className="choix-body">
            <button className="choix-btn" onClick={handleComer}>
              en tant que commercial
            </button>
              <button className="choix-btn" onClick={handleAdm}>
                en tant que {userRole.toLowerCase()}
              </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Choice;