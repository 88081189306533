import React, { useState, useEffect, useContext } from 'react';
import {
    TextField,
    Button,
    Grid,
    Paper,
    Box,
    Modal,
    Fade,
    CircularProgress,
    Container,
    Select,
    InputLabel,
    MenuItem
} from '@mui/material';
import '../../stylesComponents/sCAdmin/FormBranch.css';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import { BranchContext } from '../../Context/BrancheContext';
import { useNavigate } from 'react-router-dom';
import { createBranche, createChamps, createFormule, createOptions, createRegime } from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const FormBranch = () => {
    const { branchData, fetchBranchData } = useContext(BranchContext);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState({});
    const navigate = useNavigate();
    const [typeValeur, setTypeValeur] = useState('POURCENTAGE');
    const champDefault = { nom: '', valeur: '0', typeValeur: typeValeur };
    const regimeDefault = (nomRegime) => ({ nomRegime, champs: [{ ...champDefault }] });
    const optionDefault = (nomOption) => ({
        nomOption,
        regimes: [regimeDefault('General'), regimeDefault('Alsace_Moselle')]
    });
    const formuleDefault = () => ({ nomFormule: '', options: [optionDefault(`Option 1`)] });
    const [formules, setFormules] = useState([]);
    const [branche, setBranche] = useState('');
    const [defaultFormuleCount, setDefaultFormuleCount] = useState(0);

    useEffect(() => {
        if (defaultFormuleCount > formules.length) {
            const additionalFormulesCount = defaultFormuleCount - formules.length;
            const newFormules = Array(additionalFormulesCount).fill().map(formuleDefault);
            setFormules(formules.concat(newFormules));
        }
    }, [defaultFormuleCount, formules]);

    useEffect(() => {
        fetchBranchData();
    }, []);

    const validateData = () => {
        let isValid = true;
        const newErrors = {};

        if (!branche.trim()) {
            newErrors.branche = 'Le nom de la branche est requis.';
            isValid = false;
        }

        formules.forEach((formule, formuleIndex) => {
            const formuleErrorKey = `formule${formuleIndex}`;
            if (!formule.nomFormule.trim()) {
                newErrors[formuleErrorKey] = 'Le nom de la formule est requis.';
                isValid = false;
            } else {
                delete newErrors[formuleErrorKey];
            }

            formule.options.forEach((option, optionIndex) => {
                option.regimes.forEach((regime, regimeIndex) => {
                    regime.champs.forEach((champ, champIndex) => {
                        const champNomErrorKey = `champNom${formuleIndex}${optionIndex}${regimeIndex}${champIndex}`;
                        const champValeurErrorKey = `champValeur${formuleIndex}${optionIndex}${regimeIndex}${champIndex}`;
                        if (!champ.nom.trim()) {
                            newErrors[champNomErrorKey] = 'Le nom du champ est requis.';
                            isValid = false;
                        } else {
                            delete newErrors[champNomErrorKey];
                        }

                        if (!champ.valeur.toString().trim()) {
                            newErrors[champValeurErrorKey] = 'La valeur du champ est requise.';
                            isValid = false;
                        } else {
                            delete newErrors[champValeurErrorKey];
                        }
                    });
                });
            });
        });

        setErrorMessage(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage({});

        if (!validateData()) {
            setLoading(false);
            toast.error('Veuillez remplir tous les champs requis.');
            return;
        }

        if (branchData.some((b) => b.nomBranche.toLowerCase() === branche.toLowerCase())) {
            setLoading(false);
            toast.error('Une branche avec ce nom existe déjà.');
            return;
        }

        try {
            const branchCreated = await API.graphql(
                graphqlOperation(createBranche, { input: { nomBranche: branche } })
            );

            for (const formule of formules) {
                const formuleCreated = await API.graphql(
                    graphqlOperation(createFormule, {
                        input: {
                            nomFormule: formule.nomFormule,
                            brancheId: branchCreated.data.createBranche.id
                        }
                    })
                );

                for (const option of formule.options) {
                    const optionCreated = await API.graphql(
                        graphqlOperation(createOptions, {
                            input: {
                                nomOption: option.nomOption,
                                formuleId: formuleCreated.data.createFormule.id
                            }
                        })
                    );

                    for (const regime of option.regimes) {
                        const regimeCreated = await API.graphql(
                            graphqlOperation(createRegime, {
                                input: {
                                    nomRegime: regime.nomRegime,
                                    optionId: optionCreated.data.createOptions.id
                                }
                            })
                        );

                        for (const champ of regime.champs) {
                            await API.graphql(
                                graphqlOperation(createChamps, {
                                    input: {
                                        nom: champ.nom,
                                        valeur: champ.valeur,
                                        typeValeur: champ.typeValeur,
                                        regimeId: regimeCreated.data.createRegime.id
                                    }
                                })
                            );
                        }
                    }
                }
            }
            navigate('/branch');
            toast.success('Branche créée avec succès!');
        } catch (err) {
            console.error(err);
            setErrorMessage({
                global: 'Une erreur est survenue lors de la création de la branche. Veuillez réessayer.'
            });
            toast.error({ errorMessage });
        } finally {
            setLoading(false);
        }
    };

    const addOption = (formuleIndex, e) => {
        e.preventDefault();
        const newFormules = [...formules];
        newFormules[formuleIndex].options.push(optionDefault(`Option ${newFormules[formuleIndex].options.length + 1}`));
        setFormules(newFormules);
    };

    const addChamp = (formuleIndex, optionIndex) => {
        const newChampId = `${Date.now()}`;
        const newChamp = { id: newChampId, nom: '', valeur: '0', typeValeur: typeValeur };

        const updatedFormules = formules.map((formule, fIndex) => {
            if (fIndex === formuleIndex) {
                return {
                    ...formule,
                    options: formule.options.map((option, oIndex) => {
                        if (oIndex === optionIndex) {
                            return {
                                ...option,
                                regimes: option.regimes.map((regime) => ({
                                    ...regime,
                                    champs: [...regime.champs, newChamp]
                                }))
                            };
                        }
                        return option;
                    })
                };
            }
            return formule;
        });

        setFormules(updatedFormules);
    };

    const deleteChamp = (formuleIndex, optionIndex) => {
        const newFormules = [...formules];
        const champsGenerale = newFormules[formuleIndex].options[optionIndex].regimes[0].champs;
        const champsAlsace = newFormules[formuleIndex].options[optionIndex].regimes[1].champs;

        if (champsGenerale.length > 1 && champsAlsace.length > 1) {
            champsGenerale.pop();
            champsAlsace.pop();
        }
        setFormules(newFormules);
    };

    const deleteOption = (formuleIndex, optionIndex) => {
        const newFormules = [...formules];
        if (newFormules[formuleIndex].options.length > 1) {
            newFormules[formuleIndex].options.splice(optionIndex, 1);
        }
        setFormules(newFormules);
    };

    const deleteFormule = (formuleIndex) => {
        if (formules.length > 1) {
            const newFormules = formules.filter((_, index) => index !== formuleIndex);
            setFormules(newFormules);
            setDefaultFormuleCount(defaultFormuleCount - 1);
        }
    };

    const handleRegimeChange = (formuleIndex, optionIndex, regimeIndex, champIndex, field, value) => {
        const newFormules = [...formules];
        newFormules[formuleIndex].options[optionIndex].regimes[regimeIndex].champs[champIndex][field] = value;

        if (regimeIndex === 0 && field === 'nom') {
            newFormules[formuleIndex].options[optionIndex].regimes[1].champs[champIndex][field] = value;
        }

        setFormules(newFormules);
    };

    const handleValeurChange = (formuleIndex, optionIndex, regimeIndex, champIndex, value) => {
        let cleanedValue = value.replace(/[^0-9,]/g, '');

        if (!cleanedValue.includes(',')) {
            if (cleanedValue.length > 1) {
                cleanedValue = cleanedValue.slice(0, -1) + ',' + cleanedValue.slice(-1);
            }
        }

        let parts = cleanedValue.split(',');
        if (parts.length > 1 && parts[1].length > 2) {
            parts[1] = parts[1].substring(0, 2);
            cleanedValue = parts.join(',');
        }

        const updatedFormules = formules.map((formule, fIndex) => {
            if (fIndex === formuleIndex) {
                return {
                    ...formule,
                    options: formule.options.map((option, oIndex) => {
                        if (oIndex === optionIndex) {
                            return {
                                ...option,
                                regimes: option.regimes.map((regime, rIndex) => {
                                    if (rIndex === regimeIndex) {
                                        return {
                                            ...regime,
                                            champs: regime.champs.map((champ, cIndex) => {
                                                if (cIndex === champIndex) {
                                                    return { ...champ, valeur: cleanedValue || '0,00' };
                                                }
                                                return champ;
                                            })
                                        };
                                    }
                                    return regime;
                                })
                            };
                        }
                        return option;
                    })
                };
            }
            return formule;
        });

        setFormules(updatedFormules);
    };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleTypeValeurChange = (event) => {
        const newTypeValeur = event.target.value;
        setTypeValeur(newTypeValeur);

        const updatedFormules = formules.map((formule) => ({
            ...formule,
            options: formule.options.map((option) => ({
                ...option,
                regimes: option.regimes.map((regime) => ({
                    ...regime,
                    champs: regime.champs.map((champ) => ({
                        ...champ,
                        typeValeur: newTypeValeur // Appliquer le nouveau typeValeur
                    }))
                }))
            }))
        }));

        setFormules(updatedFormules);
    };

    const deleteChampSpecific = (formuleIndex, optionIndex, champId) => {
        const updatedFormules = formules.map((formule, fIdx) => {
            if (fIdx === formuleIndex) {
                return {
                    ...formule,
                    options: formule.options.map((option, oIdx) => {
                        if (oIdx === optionIndex) {
                            return {
                                ...option,
                                regimes: option.regimes.map((regime) => ({
                                    ...regime,
                                    champs: regime.champs.filter((champ) => champ.id !== champId)
                                }))
                            };
                        }
                        return option;
                    })
                };
            }
            return formule;
        });

        setFormules(updatedFormules);
    };

    return (
        <div className="New-Branch">
            <div className="new-banch-container">
                <form onSubmit={handleSubmit} className="my-form">
                    <div className="header-form">
                        <div className="header-form-container">
                            <div className="header-form-left">
                                <TextField
                                    style={{ width: '100%' }}
                                    label="Branche"
                                    value={branche}
                                    onChange={(e) => setBranche(capitalizeFirstLetter(e.target.value))}
                                />
                            </div>
                            <div className="header-form-right">
                                <TextField
                                    type="number"
                                    label="Nombre de formules par défaut"
                                    value={defaultFormuleCount}
                                    onChange={(e) => setDefaultFormuleCount(parseInt(e.target.value))}
                                />
                            </div>
                        </div>
                        <div className="header-form-footer">
                            <span>Comment s’appellera la branche ?</span>
                            <div>
                                <InputLabel className="demo-simple-select-label">Type </InputLabel>
                                <Select
                                    labelId="typeValeur"
                                    className="header-form-footer-select"
                                    value={typeValeur}
                                    label="typeValeur"
                                    onChange={handleTypeValeurChange}
                                >
                                    <MenuItem value="POURCENTAGE">%</MenuItem>
                                    <MenuItem value="EURO">€</MenuItem>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="formBranch-body-container">
                        <div className="container-branch">
                            <Box className="box-branch">
                                {defaultFormuleCount < 1 && (
                                    <h4>Merci de saisir le nombre de formules associés a la branche</h4>
                                )}

                                {defaultFormuleCount >= 1 &&
                                    formules.map((formule, formuleIndex) => (
                                        <Paper key={formuleIndex} className="formBranch-formule-paper">
                                            <div className="top-form">
                                                <div className="input_form">
                                                    <TextField
                                                        label="Nom de la formule"
                                                        value={formule.nomFormule}
                                                        onChange={(e) => {
                                                            const newFormules = [...formules];
                                                            newFormules[formuleIndex].nomFormule =
                                                                capitalizeFirstLetter(e.target.value);
                                                            setFormules(newFormules);
                                                        }}
                                                    />
                                                </div>
                                                <div className="btns_top">
                                                    {formuleIndex >= 1 && (
                                                        <button
                                                            type="button"
                                                            className="formBranche-form-sup"
                                                            onClick={() => deleteFormule(formuleIndex)}
                                                        >
                                                            <DeleteForeverIcon /> Supprimer formulaire
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="formBranch-option-btn">
                                                <button
                                                    type="button"
                                                    className="formBranche-btn-option-aj"
                                                    onClick={(e) => addOption(formuleIndex, e)}
                                                >
                                                    <AddIcon /> Ajouter Option
                                                </button>
                                            </div>
                                            {formule.options.map((option, optionIndex) => (
                                                <div key={optionIndex}>
                                                    <div className="formBranch-option">
                                                        <h4 className="title-option">{option.nomOption}</h4>
                                                        {optionIndex > 0 && (
                                                            <button
                                                                type="button"
                                                                className="formBranche-btn-option-sup "
                                                                onClick={() => deleteOption(formuleIndex, optionIndex)}
                                                            >
                                                                <DeleteForeverIcon /> Supprimer option
                                                            </button>
                                                        )}
                                                    </div>
                                                    {option.regimes.map((regime, regimeIndex) => (
                                                        <div key={regimeIndex}>
                                                            <h4>{regime.nomRegime}</h4>
                                                            {regime.champs.map((champ, champIndex) => (
                                                                <div className="formBranche-input-champ">
                                                                    <Grid
                                                                        container
                                                                        spacing={2}
                                                                        key={champIndex}
                                                                        style={{ display: 'flex' }}
                                                                    >
                                                                        {' '}
                                                                        <div className="formBranche-input-container">
                                                                            <Grid
                                                                                item
                                                                                xs={12}
                                                                                sm={6}
                                                                                md={5}
                                                                                className="formBranche-input-nom-champ"
                                                                            >
                                                                                <TextField
                                                                                    label="Nom"
                                                                                    value={champ.nom}
                                                                                    onChange={(e) =>
                                                                                        handleRegimeChange(
                                                                                            formuleIndex,
                                                                                            optionIndex,
                                                                                            regimeIndex,
                                                                                            champIndex,
                                                                                            'nom',
                                                                                            capitalizeFirstLetter(
                                                                                                e.target.value
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                    error={
                                                                                        !!errorMessage[
                                                                                            `champNom${formuleIndex}${optionIndex}${regimeIndex}${champIndex}`
                                                                                        ]
                                                                                    }
                                                                                    helperText={
                                                                                        errorMessage[
                                                                                            `champNom${formuleIndex}${optionIndex}${regimeIndex}${champIndex}`
                                                                                        ]
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                            <Grid
                                                                                item
                                                                                xs={6}
                                                                                sm={3}
                                                                                md={2}
                                                                                className="formBranche-input-valeur"
                                                                            >
                                                                                <TextField
                                                                                    label="Valeur"
                                                                                    value={champ.valeur}
                                                                                    onChange={(e) =>
                                                                                        handleValeurChange(
                                                                                            formuleIndex,
                                                                                            optionIndex,
                                                                                            regimeIndex,
                                                                                            champIndex,
                                                                                            e.target.value
                                                                                        )
                                                                                    }
                                                                                    error={
                                                                                        !!errorMessage[
                                                                                            `champValeur${formuleIndex}${optionIndex}${regimeIndex}${champIndex}`
                                                                                        ]
                                                                                    }
                                                                                    helperText={
                                                                                        errorMessage[
                                                                                            `champValeur${formuleIndex}${optionIndex}${regimeIndex}${champIndex}`
                                                                                        ]
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={6} sm={3} md={2}>
                                                                                <Select
                                                                                    value={champ.typeValeur}
                                                                                    onChange={(e) =>
                                                                                        handleRegimeChange(
                                                                                            formuleIndex,
                                                                                            optionIndex,
                                                                                            regimeIndex,
                                                                                            champIndex,
                                                                                            'typeValeur',
                                                                                            e.target.value
                                                                                        )
                                                                                    }
                                                                                    displayEmpty
                                                                                >
                                                                                    <MenuItem value="">
                                                                                        <em>
                                                                                            {' '}
                                                                                            default :{' '}
                                                                                            {typeValeur ===
                                                                                            'POURCENTAGE'
                                                                                                ? '%'
                                                                                                : ' € '}
                                                                                        </em>
                                                                                    </MenuItem>
                                                                                    <MenuItem value="POURCENTAGE">
                                                                                        %
                                                                                    </MenuItem>
                                                                                    <MenuItem value="EURO">€</MenuItem>
                                                                                </Select>
                                                                            </Grid>

                                                                            {champIndex !== 0 && (
                                                                                <Grid item xs={12} sm={1}>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="formBranch-btn-sup-champ-specific"
                                                                                        onClick={() =>
                                                                                            deleteChampSpecific(
                                                                                                formuleIndex,
                                                                                                optionIndex,
                                                                                                champ.id
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <DeleteOutlineIcon />
                                                                                    </button>
                                                                                </Grid>
                                                                            )}
                                                                        </div>
                                                                    </Grid>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}

                                                    <div className="formBranch-champ-buttons">
                                                        <button
                                                            type="button"
                                                            className="formBranch-btn-ajt-champ"
                                                            onClick={() => addChamp(formuleIndex, optionIndex)}
                                                        >
                                                            Ajouter un champ
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </Paper>
                                    ))}
                            </Box>
                        </div>
                    </div>
                    {errorMessage &&
                        Object.values(errorMessage).map((msg, index) => (
                            <div key={index} className="error-message">
                                {msg}
                            </div>
                        ))}

                    <div className="footer-from">
                        <Button type="submit" variant="contained" color="primary">
                            Soumettre
                        </Button>
                    </div>
                </form>
            </div>
            <Modal open={loading} closeAfterTransition>
                <Fade in={loading}>
                    <div className="loading-branch">
                        <CircularProgress style={{ color: 'whitesmoke' }} />
                        <h2 className="title-loading">Création de la branche en cours...</h2>
                    </div>
                </Fade>
            </Modal>

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
};

export default FormBranch;
