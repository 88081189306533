import { Container } from '@mui/material';
import { Checkbox, FormControlLabel, Modal, Typography, Box, Button } from '@mui/material';
import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import HeaderPage from '../components/componentsClient/HeaderPage';
import InfoIcon from '@mui/icons-material/Info';
import { BranchContext } from '../Context/BrancheContext';
import '../styles/stylesClient/FormStatus.css';
import Cookies from 'js-cookie';
import { listBranches } from '../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import MessageSupport from '../components/componentsClient/MessageSupport';
import Tooltip from '@mui/material/Tooltip';

function FormStatus() {
    const { branchData, getBranch } = useContext(BranchContext);
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState();
    const [selectedFormule, setSelectedFormule] = useState();
    const [selectedOption, setSelectedOption] = useState();
    const [selectedRegime, setSelectedRegime] = useState();
    const [branchDetails, setBranchDetails] = useState();
    const [isChecked, setIsChecked] = useState(false);
    const [numEnfants, setNumEnfants] = useState();
    const [numAscendant, setNumAscendant] = useState();
    const [champs, setChamps] = useState([{ id: 0, value: null }]);

    const [selectedChamps, setSelectedChamps] = useState([]);
    const [open, setOpen] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const [showNumEnfants, setShowNumEnfants] = useState(false);
    const [showNumAscendants, setShowNumAscendants] = useState(false);
    const [showHighestOptionMessage, setShowHighestOptionMessage] = useState(false);
    const [maxOptionNumber, setMaxOptionNumber] = useState(0);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const navigate = useNavigate();

    async function fetchBranches() {
        try {
            const branchData = await API.graphql(graphqlOperation(listBranches));
            const branches = branchData.data.listBranches.items;
            setBranches(branches);
        } catch (err) {
            console.error('error fetching branches');
        }
    }

    useEffect(() => {
        const fetchDetails = async () => {
            if (selectedBranch) {
                const data = await getBranch(selectedBranch.value);
                setBranchDetails(data);
            }
        };
        fetchDetails();
    }, [selectedBranch, getBranch]);

    useEffect(() => {
        fetchBranches();

        console.log('Branche sélectionnée : ' + JSON.stringify(selectedBranch, null, 2));
        setSelectedBranch(selectedBranch);

        console.log('Formule sélectionnée : ' + JSON.stringify(selectedFormule, null, 2));
        setSelectedFormule(selectedFormule);

        console.log('Option sélectionnée : ' + JSON.stringify(selectedOption, null, 2));
        setSelectedOption(selectedOption);

        console.log('Régime sélectionné : ' + JSON.stringify(selectedRegime, null, 2));
        setSelectedRegime(selectedRegime);

        console.log('Champ sélectionné : ' + JSON.stringify(selectedChamps, null, 2));
        setSelectedChamps(selectedChamps);

        console.log('nb : ' + selectedChamps.length);
        //console.log("branche" , JSON.stringify(selectedBranch?.label,null,2))
        //console.log("champ du bon regime ", JSON.stringify(selectedRegime?.champs.items, null,2))
    }, [selectedBranch, selectedFormule, selectedOption, selectedRegime, selectedChamps]);

    useEffect(() => {
        if (selectedFormule && selectedFormule.options && selectedFormule.options.items) {
            const optionsNoms = selectedFormule.options.items.map((option) => option.nomOption);

            //console.log("Noms des Options: ", optionsNoms);
            const optionsNum = selectedFormule.options.items.map((option) => {
                const match = option.nomOption.match(/\d+/);
                return match ? parseInt(match[0], 10) : 0;
            });

            const highetoptionNum = Math.max(...optionsNum);

            //console.log("Num option max: ", highetoptionNum);
            const selectedOptionNum = (() => {
                const match = selectedOption?.nomOption.match(/\d+/);
                return match ? parseInt(match[0], 10) : 0;
            })();

            const isHighestOptionSelected = highetoptionNum === selectedOptionNum;

            if (isHighestOptionSelected) {
                setShowHighestOptionMessage(true);
            } else {
                setShowHighestOptionMessage(false);
            }
        }
    }, [selectedFormule, selectedOption]);

    const branchOptions = branchData.map((branch) => ({ value: branch.id, label: branch.nomBranche }));
    const formuleOptions = branchDetails?.formules.items.map((formule) => ({
        value: formule.id,
        label: formule.nomFormule
    }));
    const optionOptions = selectedFormule?.options.items
        .map((option) => ({ value: option.id, label: option.nomOption }))
        .sort((a, b) => {
            return a.label.localeCompare(b.label);
        });

    const regimeOptions = selectedOption?.regimes.items.map((regime) => ({
        value: regime.id,
        label: regime.nomRegime
    }));
    const champOptions = selectedRegime?.champs.items.map((champ) => ({
        value: champ.id,
        label: champ.nom,
        valeur: champ.valeur
    }));

    const handleSuivant = () => {
        if (selectedBranch.label.includes('Habitat') && selectedOption.nomOption !== 'Option 2') {
            if (
                !selectedBranch ||
                !selectedBranch.value ||
                !selectedFormule ||
                !selectedFormule.nomFormule ||
                !selectedOption ||
                !selectedOption.nomOption ||
                !selectedRegime ||
                !selectedRegime.nomRegime ||
                (selectedChamps.length < 1 && selectedFormule.nomFormule.includes('Adulte'))
            ) {
                toast.error('Merci de remplir tous les champs ');
                return;
            }
        }

        if (selectedBranch && selectedFormule && selectedOption && selectedRegime) {
            if (
                selectedBranch.label.includes('Habitat') &&
                selectedFormule.nomFormule.includes('Adulte') &&
                selectedChamps.length === 0 &&
                selectedOption.nomOption === 'Option 2'
            ) {
                const salarieChamp = selectedRegime.champs.items.find((champ) =>
                    champ.nom.toLowerCase().includes('salarié')
                );

                if (salarieChamp) {
                    const isSalarieAlreadySelected = selectedChamps.some((champ) => champ.label === salarieChamp.nom);
                    if (!isSalarieAlreadySelected) {
                        setSelectedChamps([
                            ...selectedChamps,
                            {
                                value: salarieChamp.id,
                                label: salarieChamp.nom,
                                valeur: salarieChamp.valeur
                            }
                        ]);
                    }
                }
            }
        }

        if (selectedChamps.length > 0) {
            const champsData = selectedChamps.map((champ) => {
                return {
                    label: champ.label,
                    valeur: champ.valeur
                };
            });
            Cookies.set('selectedChamps', JSON.stringify(champsData), { expires: 7 });
        }

        Cookies.set('branche', selectedBranch.label, { expires: 7 });
        Cookies.set('setFormule', selectedFormule.nomFormule, { expires: 7 });
        Cookies.set('setFormuleId', selectedFormule.id, { expires: 7 });
        Cookies.set('setOption', selectedOption.nomOption, { expires: 7 });

        if (!selectedRegime || !selectedRegime.nomRegime) {
            toast.error('Veuillez sélectionner un régime.');
            return;
        } else {
            Cookies.set('setRegime', selectedRegime.nomRegime, { expires: 7 });
        }

        if (
            !selectedFormule.nomFormule.includes('Isolé') &&
            !(selectedBranch.label.includes('Habitat') && selectedFormule.nomFormule.includes('Adulte'))
        ) {
            if (selectedChamps.length === 0) {
                toast.error('Veuillez sélectionner au moins un champ pour la couverture.');
                return;
            } else {
                const champsData = selectedChamps.map((champ) => {
                    return {
                        label: champ.label,
                        valeur: champ.valeur
                    };
                });
                Cookies.set('selectedChamps', JSON.stringify(champsData), { expires: 7 });
            }
        }

        if (selectedFormule?.nomFormule.includes('Famille') && selectedBranch?.label.includes('Habitat')) {
            Cookies.set('selectedChamps', JSON.stringify(selectedChamps), { expires: 7 });
        }

        if (selectedFormule?.nomFormule.includes('Famille') && selectedBranch?.label.includes('Architecte')) {
            Cookies.set('selectedChamps', JSON.stringify(selectedChamps), { expires: 7 });
        }

        Cookies.set('numEnfants', numEnfants?.value, { expires: 7 });
        Cookies.set('numAscendants', numAscendant?.value, { expires: 7 });

        let isConjointSelected = selectedChamps.some((champ) => champ?.label?.includes('Conjoint'));
        Cookies.set('numConjoints', isConjointSelected ? '1' : '0', { expires: 7 });

        // Vérifiez les cookies écrits mises à jour
        console.log('Cookies avant navigation:', Cookies.get());

        // Navigation
        navigate(`/updateStatus/${selectedBranch.value}`);
    };

    const handleAddChamp = () => {
        const maxChamps = selectedBranch?.label.includes('Habitat') ? 3 : 2;

        if (champs.length < maxChamps) {
            const newId = champs.length === 0 ? 1 : Math.max(...champs.map((c) => c.id)) + 1;
            setChamps([...champs, { id: newId, value: null }]);
        } else {
            toast.error(`Vous ne pouvez ajouter que ${maxChamps} champs au maximum.`);
        }
    };

    const handleChampChange = (selected, index) => {
        const updatedChamps = [...champs];
        updatedChamps[index] = { ...updatedChamps[index], value: selected };
        setChamps(updatedChamps);

        const newSelectedChamps = updatedChamps.map((champ) => champ.value);
        //console.log("champs :", JSON.stringify(newSelectedChamps, null, 2));
        setSelectedChamps(newSelectedChamps);

        setShowNumEnfants(
            updatedChamps.some(
                (champ) => champ.value?.label.includes('Enfant(s)') || champ.value?.label.includes('enfant')
            )
        );
        setShowNumAscendants(
            updatedChamps.some(
                (champ) => champ.value?.label.includes('Ascendant(s)') || champ.value?.label.includes('ascendant')
            )
        );
    };

    const handleDeleteChamp = (index) => {
        const newChamps = champs.filter((_, i) => i !== index);
        setChamps(newChamps);

        const enfantsPresent = newChamps.some(
            (champ) => champ.value?.label.includes('Enfant(s)') || champ.value?.label.includes('enfant')
        );
        const ascendantsPresent = newChamps.some(
            (champ) => champ.value?.label.includes('Ascendant(s)') || champ.value?.label.includes('ascendant')
        );

        setShowNumEnfants(enfantsPresent);
        setShowNumAscendants(ascendantsPresent);

        if (!enfantsPresent) {
            setNumEnfants(null);
        }

        if (!ascendantsPresent) {
            setNumAscendant(null);
        }
    };

    const getAvailableOptions = () => {
        const selectedValues = champs.map((champ) => champ.value?.label);
        let optionsToShow = champOptions || [];

        const isSpecialBranch = ['PTPC', 'Cartonnage'].some((branch) => selectedBranch?.label?.includes(branch));
        const isHabitatWithChildren =
            selectedBranch?.label?.includes('Habitat') && selectedFormule?.nomFormule?.includes('Enfant');
        const isNotIsolatedFormula = selectedFormule?.nomFormule && !selectedFormule.nomFormule.includes('Isolé');

        if (!isSpecialBranch && isNotIsolatedFormula) {
            optionsToShow = optionsToShow.filter((option) => option.label !== 'Salarié');
        }

        if (isHabitatWithChildren) {
            optionsToShow = optionsToShow.filter((option) => option.label !== 'Salarié');
        }

        optionsToShow = optionsToShow.filter((option) => !option.label.toLowerCase().includes('extension'));

        return optionsToShow.filter((option) => !selectedValues.includes(option.label));
    };

    useEffect(() => {
        if (selectedFormule && selectedFormule.options && selectedFormule.options.items) {
            const highestOptionNumber = Math.max(
                ...selectedFormule.options.items.map((option) => {
                    const match = option.nomOption.match(/\d+/);
                    return match ? parseInt(match[0], 10) : 0;
                })
            );

            const selectedOptionNumber = selectedOption?.nomOption.match(/\d+/)
                ? parseInt(selectedOption?.nomOption.match(/\d+/)[0], 10)
                : 0;
            const isHighestOptionSelected = highestOptionNumber === selectedOptionNumber;
            const isFormuleExcluded =
                selectedFormule.nomFormule === 'Isolé' || selectedFormule.nomFormule.includes('sans-conjoint');

            setShowHighestOptionMessage(isHighestOptionSelected && !isFormuleExcluded);
        }
    }, [selectedFormule, selectedOption]);

    const motsClesIsolé = ['Enfant(s)', 'enfant', 'Ascendant(s)', 'ascendant', 'Conjoint'];
    const motsClesDuo = ['Extension', 'Conjoint', 'Enfant(s)'];

    const champ = champOptions || [];
    let optionsToShow = champ;

    const formuleParts = selectedFormule?.nomFormule?.split('-') || [];

    const containsDuo = formuleParts.some((part) => part.includes('Duo'));

    if (selectedBranch?.label?.includes('Bureaux')) {
        optionsToShow = optionsToShow.filter((option) => option.label !== 'Conjoint');
    }

    if (containsDuo) {
        optionsToShow = champ.filter(
            (option) => !motsClesDuo.some((motCle) => option.label?.toLowerCase().includes(motCle.toLowerCase()))
        );
    } else {
        if (formuleParts.some((part) => part.includes('Isolé'))) {
            optionsToShow = champ.filter(
                (option) => !motsClesIsolé.some((motCle) => option.label?.toLowerCase().includes(motCle.toLowerCase()))
            );
        } else if (formuleParts.some((part) => part.includes('Enfant'))) {
            optionsToShow = champ.filter(
                (option) => !motsClesDuo.some((motCle) => option.label?.toLowerCase().includes(motCle.toLowerCase()))
            );
        }
    }

    const handleSelectedRegimeChange = (selectedRegimeValue) => {
        const foundRegime = selectedOption.regimes.items.find((regime) => regime.id === selectedRegimeValue.value);
        setSelectedRegime(foundRegime);

        if (foundRegime.champs && foundRegime.champs.items && foundRegime.champs.items.length === 1) {
            setSelectedChamps([foundRegime.champs.items[0]]);
            setShowMessage(true);
        }

        if (selectedFormule?.nomFormule === 'Isolé') {
            if (selectedBranch.label.includes('Habitat') && selectedOption.nomOption !== 'Option 2') {
                const salarieOption = foundRegime.champs.items.find((item) => item.nom === 'Salarié');
                // if (salarieOption) {
                //     setSelectedChamps([salarieOption]);
                // }
            }
        }
    };

    useEffect(() => {
        if (selectedBranch?.label?.includes('Bureau') && selectedRegime) {
            const salarieChamp = selectedRegime.champs.items.find((champ) =>
                champ.nom.toLowerCase().includes('salarié')
            );
            if (salarieChamp) {
                setSelectedChamps([
                    {
                        value: salarieChamp.id,
                        label: salarieChamp.nom,
                        valeur: salarieChamp.valeur,
                        isDefault: true
                    }
                ]);
                console.log('selectionner par defaut : ', selectedChamps);
            }
        }
    }, [selectedBranch, selectedRegime]);

    const maximalOption = () => {
        const allCookies = Cookies.get();
        Object.keys(allCookies).forEach((cookie) => {
            if (cookie !== 'email') {
                Cookies.remove(cookie);
            }
        });
        window.location.reload();
        setOpen(false);
    };

    const handlereset = () => {
        const allCookies = Cookies.get();
        Object.keys(allCookies).forEach((cookie) => {
            if (cookie !== 'email') {
                Cookies.remove(cookie);
            }
        });
        window.location.reload();
    };

    const isHabitatSocial = selectedBranch && selectedBranch.label.includes('Habitat');

    const branchesAvecEnfant = ['Habitat', 'Cartonnage', 'PTPC'];

    const isBrancheAvecEnfant = branchesAvecEnfant.some((branche) => selectedBranch?.label?.includes(branche));

    return (
        <div className="formStatus">
            <div className="formStatus-container">
                <HeaderPage />
                <Container className="space-select">
                    <div className="select-container">
                        <div className="formStatus-header-title">
                            <h2 className="formStatus-title">Situation actuelle du salarié</h2>
                            <Tooltip
                                title={
                                    <span style={{ fontSize: '14px', display: 'flex', alignItems: 'center' }}>
                                        <InfoIcon /> Reinitialiser le formulaire{' '}
                                    </span>
                                }
                            >
                                <button className="formStatus-btn-reset" onClick={handlereset}>
                                    <RotateLeftIcon />
                                </button>
                            </Tooltip>
                        </div>
                        <br></br>
                        <label className="select-label branch-label">Branche du Contrat Obligatoire:</label>
                        <Select
                            options={branchOptions}
                            onChange={setSelectedBranch}
                            placeholder="Choisissez une branche..."
                            className="select-input branch-select"
                        />
                        <label className="select-label formule-label">Formule Tarifaire Obligatoire :</label>
                        <Select
                            options={formuleOptions}
                            onChange={(selectedFormule) => {
                                setSelectedFormule(
                                    branchDetails.formules.items.find((formule) => formule.id === selectedFormule.value)
                                );
                            }}
                            placeholder="Choisissez une formule..."
                            className="select-input formule-select"
                        />
                        <label className="select-label option-label">Option Obligatoire :</label>
                        <Select
                            options={optionOptions}
                            onChange={(selectedOption) => {
                                setSelectedOption(
                                    selectedFormule.options.items.find((option) => option.id === selectedOption.value)
                                );
                            }}
                            placeholder="Choisissez une option..."
                            className="select-input option-select"
                        />
                        <label className="select-label regime-label">Régime :</label>
                        <Select
                            options={regimeOptions}
                            onChange={handleSelectedRegimeChange}
                            placeholder="Choisissez un régime..."
                            className="select-input regime-select"
                        />
                        <div className="formStatus-select-bottom">
                            {selectedFormule?.nomFormule &&
                            (selectedFormule?.nomFormule.includes('Enfant') ||
                                selectedFormule?.nomFormule.includes('Adulte/Enfant')) ? (
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                                name="checkBoxTest"
                                                color="primary"
                                                style={{ paddingLeft: '10px' }}
                                            />
                                        }
                                        label="Indiquer les ayants-droit couverts par le contrat obligatoire afin de tarifier l'option facultative ?"
                                    />
                                    {isChecked &&
                                        champs.map((champ, index) => (
                                            <div className="formStatus-select-ayant" key={champ.id}>
                                                <div className="formStatus-select-ayant-body">
                                                    <label className="select-label champ-label">
                                                        Personne couverte :
                                                    </label>
                                                    <Select
                                                        options={getAvailableOptions()}
                                                        value={champ.value}
                                                        onChange={(selected) => handleChampChange(selected, index)}
                                                        placeholder="Choisissez un champ..."
                                                        className="select-input champ-select"
                                                    />
                                                </div>
                                                <button
                                                    className="formStatus-btn-ayant-del"
                                                    onClick={() => handleDeleteChamp(champ.id, index)}
                                                >
                                                    Supprimer
                                                </button>
                                            </div>
                                        ))}
                                </div>
                            ) : (
                                selectedFormule?.nomFormule &&
                                selectedFormule?.nomFormule !== 'Isolé' &&
                                selectedFormule?.nomFormule !== 'Famille-sans-conjoint ' &&
                                optionsToShow.length > 1 &&
                                champs.map((champ, index) => (
                                    <div key={index} className="formStatus-select-default">
                                        <label className="select-label champ-label">personne couverte :</label>
                                        <Select
                                            options={optionsToShow}
                                            onChange={(selected) => handleChampChange(selected, index)}
                                            placeholder="Choisissez un champ..."
                                            className="select-input champ-select"
                                        />
                                    </div>
                                ))
                            )}

                            {isBrancheAvecEnfant && showNumEnfants && (
                                <div className="select-footer-container">
                                    <label className="select-label">Nombre d'enfants :</label>
                                    <Select
                                        value={numEnfants}
                                        onChange={setNumEnfants}
                                        options={
                                            isHabitatSocial
                                                ? [
                                                      { value: 1, label: '1' },
                                                      { value: 2, label: '2' },
                                                      { value: 3, label: '3 ou +' }
                                                  ]
                                                : [
                                                      { value: 1, label: '1' },
                                                      { value: 2, label: '2 ou +' }
                                                  ]
                                        }
                                        placeholder="Choisissez le nombre d'enfants..."
                                        className="select-input"
                                    />
                                </div>
                            )}

                            {showNumAscendants && (
                                <div className="select-footer-container">
                                    <label className="select-label">Nombre d'ascendants :</label>
                                    <Select
                                        value={numAscendant}
                                        onChange={setNumAscendant}
                                        options={[
                                            { value: 1, label: '1' },
                                            { value: 2, label: '2' },
                                            { value: 3, label: '3' },
                                            { value: 4, label: '4' }
                                        ]}
                                        placeholder="Choisissez le nombre d'ascendants..."
                                        className="select-input"
                                    />
                                </div>
                            )}

                            {isChecked && (
                                <div className="formStatus-option-champ">
                                    <button className="formStatus-btn-add" onClick={handleAddChamp}>
                                        Ajouter un ayant-droit
                                    </button>
                                </div>
                            )}

                            {selectedFormule?.nomFormule !== 'Isolé' && selectedChamps.length !== 0 && (
                                <p className="formStatus-alert">
                                    <InfoIcon style={{ marginTop: '-2px' }} /> L'ajout{' '}
                                    {selectedBranch &&
                                    selectedBranch?.label?.includes('Bureaux') &&
                                    selectedFormule?.nomFormule.includes('sans-conjoint')
                                        ? 'des enfants '
                                        : "d'ayants droit "}
                                    est géré au niveau du contrat obligatoire via l'entreprise, veuillez vous rapprocher
                                    de votre entreprise selon vos besoins.
                                </p>
                            )}
                            {/* {showHighestOptionMessage || !selectedFormule?.nomFormule.includes("sans-conjoint") || selectedOption.length > 0 && (
                                    <p className='formulaire-alert2' style={{color: "#00314C"}}>
                                        <InfoIcon/>{" "}Vous disposez de l'option la plus élevée, aucune amélioration n'est disponible
                                    </p>
                                )}
                            */}

                            {/* { showMessage && <p className='formulaire-alert2' style={{color: "#00314C"}}>
                                <InfoIcon/>{" "}Personne couverte a été sélectionnée par défaut. Faites Suivant.</p> }
                            */}
                        </div>
                    </div>
                </Container>
                <div className="formStatus-footer">
                    {showHighestOptionMessage ? (
                        <>
                            <Modal
                                open={showHighestOptionMessage}
                                onClose={() => setOpen(false)}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        bgcolor: 'background.paper',
                                        boxShadow: 24,
                                        p: 4
                                    }}
                                >
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        🚫 Fin de la procédure de tarification
                                    </Typography>
                                    <Typography id="modal-modal-description" variant="body1" component="p" my={2}>
                                        Vous disposez de l'option la plus élévée, aucune amélioration n'est disponible
                                    </Typography>
                                    <Button onClick={maximalOption} variant="contained">
                                        Effectuer une nouvelle tarification
                                    </Button>
                                </Box>
                            </Modal>
                        </>
                    ) : (
                        <button className="formStatus-btn" onClick={handleSuivant}>
                            Suivant
                        </button>
                    )}
                </div>
                <MessageSupport />
            </div>
        </div>
    );
}

export default FormStatus;
