import React, { useEffect, useState } from 'react';
import { Container, Typography, Divider, List, ListItem, ListItemText } from '@mui/material';
import Navbar from '../components/componentsAdmin/Navbar';
import { useConfig } from '../Context/ConfigContext';
import '../styles/stylesAdmin/SettingsPage.css';
import { toast } from 'react-toastify';

function SettingsPage() {
    const { pmss, year, updateConfig, loading } = useConfig();
    const [newPmss, setNewPmss] = useState(pmss);
    const [newYear, setNewYear] = useState(year);

    // Synchroniser newPmss et newYear avec les valeurs actuelles
    useEffect(() => {
        if (pmss !== null && pmss !== undefined) {
            setNewPmss(pmss);
        }
        if (year !== null && year !== undefined) {
            setNewYear(year);
        }
    }, [pmss, year]);

    // Fonction pour sauvegarder les changements
    const handleUpdate = async () => {
        try {
            await updateConfig(newPmss, newYear);
            console.log('PMSS et année mis à jour avec succès :', { pmss: newPmss, year: newYear });
        } catch (error) {
            console.error('Erreur lors de la mise à jour :', error.message);
            toast.error(`Erreur lors de la mise à jour : ${error.message}`);
        }
    };

    return (
        <div>
            <Navbar />
            <div class="settings-page">
                <Container className="settings-container">
                    <Typography variant="h4" style={{ padding: '25px 0', color: 'black' }}>
                        Paramètres
                    </Typography>

                    <div className="settings-section">
                        <Typography variant="h5" style={{ paddingBottom: '40px', color: 'black' }}>
                            Mise à jour des configurations
                        </Typography>
                        <Typography variant="h6" className="settings-section-title">
                            Valeur du PMSS
                        </Typography>
                        <Divider className="settings-divider" />
                        <List className="settings-list" style={{ paddingTop: '20px' }}>
                            {/* <input
                                type="number"
                                value={pmss}
                                className="select-option"
                                style={{ width: '200px', paddingLeft: '25px', textAlign: 'center', fontSize: '18px' }}
                                placeholder="PMSS (€)"
                                min="0"
                            /> */}
                            <div className="input-container">
                                <input
                                    type="number"
                                    value={newPmss}
                                    onChange={(e) => setNewPmss(Number(e.target.value))}
                                    className="select-option"
                                    style={{
                                        width: '200px',
                                        paddingLeft: '25px',
                                        textAlign: 'center',
                                        fontSize: '18px'
                                    }}
                                    placeholder="PMSS (€)"
                                    min="0"
                                    disabled={loading}
                                />
                                {/* <button className="update-btn" onClick={handleUpdate} disabled={loading}>
                                    {loading ? 'Mise à jour...' : 'Mettre à jour'}
                                </button> */}
                            </div>
                        </List>
                    </div>
                    <div className="settings-section">
                        <Typography variant="h6" className="settings-section-title">
                            Année effective
                        </Typography>
                        <Divider className="settings-divider" />
                        <List className="settings-list" style={{ paddingTop: '20px' }}>
                            <div className="input-container">
                                <input
                                    type="number"
                                    value={newYear}
                                    onChange={(e) => setNewYear(Number(e.target.value))}
                                    className="select-option"
                                    style={{
                                        width: '200px',
                                        paddingLeft: '25px',
                                        textAlign: 'center',
                                        fontSize: '18px'
                                    }}
                                    placeholder="Année"
                                    min="2000"
                                    max="2100"
                                    disabled={loading}
                                />
                            </div>
                        </List>
                    </div>
                    <div className="formUpdate-footer">
                        <button className="update-btn" onClick={handleUpdate} disabled={loading}>
                            {loading ? 'Mise à jour...' : 'Mettre à jour'}
                        </button>
                    </div>
                    {/* <div className="settings-section">
                        <Typography variant="h6" className="settings-section-title">
                            Paramètres de l'utilisateur
                        </Typography>
                        <Divider className="settings-divider" />
                        <List className="settings-list">
                            <ListItem button className="settings-list-item">
                                <ListItemText primary="Exemples à recopier ..." className="settings-list-item-text" />
                                <ListItemText primary="Autres paramètres..." className="settings-list-item-text" />
                            </ListItem>
                        </List>
                    </div>
                    <div className="settings-section">
                        <Typography variant="h6" className="settings-section-title">
                            Gestion des accès et permissions
                        </Typography>
                        <Divider className="settings-divider" />
                        <List className="settings-list">
                            <ListItem button className="settings-list-item">
                                <ListItemText primary="Rôles et permissions" className="settings-list-item-text" />
                            </ListItem>
                            <ListItem button className="settings-list-item">
                                <ListItemText primary="Accès des utilisateurs" className="settings-list-item-text" />
                            </ListItem>
                        </List>
                    </div> */}
                </Container>
            </div>
        </div>
    );
}

export default SettingsPage;
