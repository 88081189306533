import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../stylesComponents/sCClient/HeaderPage.css';

function HeaderPage({ title }) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/');
    };
    return (
        <div className="headerPage">
            <h2 className="header-title"> {title} </h2>
            <button onClick={handleClick} className="btn-deco">
                {' '}
                Se déconnecter
            </button>
        </div>
    );
}

export default HeaderPage;
