import React from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useNavigate } from 'react-router-dom';


const MessageSupport = () => {
 const navigate = useNavigate()

 const handleClick = () => {
  navigate("/help")
 }

  return (
      <div style={{ position: 'fixed', bottom: 100, right: 40 }}>
          <Tooltip title={<span style={{ fontSize: '14px' }}>Cliquez ici pour accéder à l'assistance</span>}>
              <Button
                  onClick={handleClick}
                  style={{ color: 'gray' }}
                  sx={{
                      '&:hover': {
                          backgroundColor: 'transparent', // Supprime le changement de fond
                          boxShadow: 'none' // Supprime l'ombre au hover, si présente
                      }
                  }}
              >
                  <HelpOutlineIcon />
              </Button>
          </Tooltip>
      </div>
  );
};

export default MessageSupport;
